.ch-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    background: app-color('primary');

    @include mq($until: tablet) {
        justify-content: flex-start;
    }

    &-logo {
        width: 200px;
        height: 70px;

        @include mq($until: tablet) {
            width: 240px;
            height: 70px;
        }
    }

    &-nav {
        @include app-typography('heading-S');
        color: app-color('border');
        display: flex;
        gap: 100px;
        cursor: pointer;

        &:where(.desktop) {
            @include mq($until: tablet) {
                display: none;
            }
        }

        div:after {
            padding-top: 3px;
            display:block;
            content: '';
            border-bottom: solid 3px app-color(border);  
            transform: scaleX(0);  
            transition: transform 250ms ease-in-out;
            transform-origin:  0% 50%;
        }
        div:hover:after {
            transform: scaleX(1);
        }
    }

    nav {
        position: relative;
        width: 100px;
        margin-left: -40px;
        margin-top: -20px;
      
        @include mq($from: tablet) {
          display: none;
        }
      }
      
    .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        background: #fff;
        height: 100vh;
        z-index: 191;
    }
    
    button {
        outline: none;
        border: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: pointer;
        position: relative;
        top: 18px;
        left: 15px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: transparent;
        z-index: 199;
    }
    
    ul,
    li {
        margin: 0;
        padding: 0;
    }
    
    ul {
        padding: 25px;
        position: absolute;
        top: 100px;
        width: 230px;
        z-index: 191;
    }
    
    li {
        list-style: none;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    
    .icon-placeholder {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex: 40px 0;
        margin-right: 20px;
        margin-left: 20px;
        margin-top: 10px;
        .anticon {
            color: app-color('border');
            font-size: xx-large;
        }
    }
    
    .text-placeholder {
        border-radius: 5px;
        width: 200px;
        height: 20px;
        flex: 1;
        color: app-color('border');
        @include app-typography('heading-M')
    }
}

.ch-fixed-header {
    background: rgba(app-color('secondary'), 0.9) !important;
    border-bottom: 1px solid app-color('border');

    @include mq($until: tablet) {
        max-width: 100vw;
        padding-bottom: 20px;
    }

    .ch-header-nav {
        color: app-color('dark-grey');
    }
}