@import './settings';
@import './tools';
@import './elements';
@import './components';
@import './pages';

::selection {
  background: app-color('border');
  color: app-color('white');
}

html,
body {
  max-width: 100vw;
  min-width: 360px;
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  color: app-color('white');
  background: app-color('primary');
  overflow-x: hidden;
  margin: 0;
  scroll-behavior: smooth;
}

p {
  margin-bottom: 0;
}