.ch-shop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
    &-product-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        row-gap: 30px;
        column-gap: 10px;
    }
    &-search-container {
        display: flex;
        flex-direction: row;
        max-width: 700px;
        width: 100%;
        margin-bottom: 40px;
        justify-content: center;
        border: 2px solid app-color('border');
        border-radius: 8px;
        .ch-btn {
            max-width: 100px;
            border: none;
            border-radius: 0;
        }
        .ant-input {
            border: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 2px solid app-color('border');
            color: app-color('border');
            @include app-typography('heading-S')
        }
    }
}