.ch-about {
    &-page {
        display: flex;
        justify-content: center;
    }
    &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 800px;
    }
    &-title {
        @include app-typography('heading-M');
        color: app-color('border');
        text-align: center;
        margin-bottom: 40px;
    }
    &-desc {
        @include app-typography('heading-S');
        margin-bottom: 20px;
        line-height: 30px !important;
        text-align: left;
    }
    &-sign {
        @include app-typography('body-S-semi-bold');
        color: app-color('border');
        font-style: italic;
        text-align: end;
        margin-bottom: 40px;
        @include mq($until: mobile) {
            text-align: center;
        }
    }
}