.ch-btn {
  @include app-typography('heading-S');
  width: 100%;
  height: 48px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon-spin {
    font-size: 32px !important;
  }

  .anticon {
    color: app-color('white');
  }

  @include mq($from: mobile) {
    max-width: 318px;
  }
}

:where(.ch-btn).ant-btn-primary {
  background-color: app-color('border');
  color: app-color('white');
  border: none;
  padding: 4px 15px;
  border-radius: 50px;
  border: 2px solid app-color('white');
  transition: all 800ms ease-in-out;

  &:not(:disabled):hover {
    background-color: rgba(app-color('primary'), .4) !important;
    border-color: app-color('border') !important;
    color: app-color('border') !important;
    scale: 1.1;
    transition: all 800ms ease-in-out;
  }

  &:not(:disabled):active {
    background-color: app-color('border');
  }

  &:disabled {
    border: none;
    background-color: app-color('light-grey');
    color: app-color('grey-2');
  }
}

:where(.ch-btn).ant-btn-default {
  background-color: app-color('transparent');
  color: app-color('border');
  padding: 4px 15px;
  border: 2px solid app-color('border');
  border-radius: 50px;

  &:not(:disabled):hover {
    background-color: app-color('border') !important;
    border-color: app-color('border') !important;
    color: app-color('primary') !important;
  }

  &:not(:disabled):active {
    background-color: app-color('white');
    border-color: app-color('white');
    color: app-color('dark-grey');
  }

  &:disabled {
    border-color: app-color('light-grey');
    background-color: app-color('transparent');
    color: app-color('light-grey');
  }
}

.gv-back-to-top {
  background-color: app-color('dark-grey') !important;
  opacity: 0.6 !important;
  inset-block-end: 24px;

  .ant-float-btn-body {
    background-color: transparent !important;

    .ant-float-btn-icon {
      width: 24px !important;
    }
  }

  @include mq($until: mobile) {
    inset-block-end: 12px;
    inset-inline-end: 12px;
  }


}

.ant-btn-background-ghost {
  border: none;
}

.gv-back-to-top:hover {
  opacity: 0.9 !important;
}