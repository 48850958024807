.ch-product-item {
    &-card {
        padding: 20px;
        border: 1px solid app-color('border');
        background-color: app-color('primary') !important;
        border-radius: 10px;
        max-width: 317px;
        width: 100%;
        z-index: 1;
        cursor: pointer;
        box-shadow: 0 2.8px 2.2px rgba(app-color('border'), 0.034),
        0 6.7px 5.3px rgba(app-color('border'), 0.048),
        0 12.5px 10px rgba(app-color('border'), 0.06),
        0 22.3px 17.9px rgba(app-color('border'), 0.072),
        0 41.8px 33.4px rgba(app-color('border'), 0.086),
        0 100px 80px rgba(app-color('border'), 0.12);

        @include mq($until: tablet) {
            width: calc(100vw - 80px) !important;
        }
        &-container {
            position: relative;
        }
        &-img {
            position: relative !important;
            border-radius: 10px;
            width: 100% !important;
            height: 275px !important;
        }
        &-category {
            @include app-typography('body-S-semi-bold');
            color: app-color('border');
            padding: 10px 0;
        }
        &-title {
            @include app-typography('heading-S-semi-bold');
            text-align: left;
            padding-bottom: 25px;
            min-height: 60px;
            &:hover {
                text-decoration: underline;
            }
        }
        &-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 15px;
        }
        &-price {
            @include app-typography('heading-S-semi-bold');
            color: app-color('grey');
        }
        &-availability {
            @include app-typography('body-S-semi-bold');
        }
        &-actions {
            display: flex;
            justify-content: center;
            padding: 20px 20px;
        }
    }
}