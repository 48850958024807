.ch-home-carousel {
    min-width: 100%;
    height: calc(90vh - 121px); //carousel + padding top on layout
    position: relative;
    overflow: hidden;

    @include mq($until: tablet) {
        height: calc(80vh - 121px);
        margin-left: -50px;
        margin-right: -50px;
        margin-top: -30px;
    }

    &-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &-slide {
        position: relative;
        width: 100%;
        height: 100%;

        &-img {
            width: 100%;
            height: 100%;
            position: relative;
            img {
                object-fit: fill;
            }
            
            @include mq($until: tablet) {
                img {
                    object-fit: cover;
                }
            }
        }
        &-holder {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            z-index: 99;
            width: 100%;
            height: 100%;
            background: app-color('transparent');
            padding-bottom: 100px;
            top: 0;
        }
        &-desc {
            @include app-typography('body-M');
            background: app-color('secondary');
            padding: 0 10px;
            margin-left: auto;
            margin-right: auto;
            color: app-color('border');
            border-radius: 3px;
            p {
                margin-bottom: 30px;
            }
        }
    }
}