.ch-product {
    &-page {
        position: relative;
    }
    &-title {
        @include app-typography('heading-L');
        color: app-color('dark-grey');
        margin-bottom: 50px;
    }
    &-container {
        display: flex;
        gap: 50px;
        @include mq ($until: tablet) {
            flex-direction: column;
        }
    }
    &-image {
        border-radius: 8px;
        max-width: 500px;
        max-height: 500px;
        width: 500px;
        height: 500px;
        @include mq ($until: tablet) {
            width: calc(100vw - 100px) !important;
            height: calc(100vw - 100px) !important;
        }
        &-list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 500px;
            max-width: calc(100vw - 100px);
            overflow: auto;
            &-item {
                min-width: 100px;
                border: 1px solid app-color('border');
                border-radius: 8px;
                cursor: pointer;
                object-fit: cover;
            }
            @include mq ($until: tablet) {
                flex-direction: row;
                padding-bottom: 10px;
            }
        }
        &-container {
            display: flex;
            flex-direction: row;
            gap: 10px;
            @include mq ($until: tablet) {
                flex-direction: column-reverse;
            }
        }
        &-main-container {
            max-width: 500px;
            max-height: 500px;
            width: 500px;
            height: 500px;
            overflow: hidden;
            position: relative;
            border-radius: 8px;
            border: 1px solid app-color('border');

            @include mq ($until: tablet) {
                width: calc(100vw - 100px) !important;
                height: calc(100vw - 100px) !important;
            }
        }
    }
    &-details {
        width: 100%;
        margin-top: 10px;
        &-price {
            @include app-typography('heading-M');
            color: app-color('border');
            margin-bottom: 30px;
        }
        &-item {
            border-top: 1px solid app-color('border');
            padding: 40px 0;

            &-title {
                @include app-typography('body-S-bold');
                color: app-color('dark-grey');
                margin-bottom: 20px;
            }
            &-note {
                @include app-typography('body-S');
                color: app-color('grey');
                margin-bottom: 20px;
            }
            &-description {
                @include app-typography('body-S');
                color: app-color('dark-grey');
                white-space: pre-wrap;
                line-height: 22px !important;
            }
            &-variants {
                display: flex;
                flex-wrap: wrap;
                column-gap: 100px;
                row-gap: 25px;

                .size-variant {
                    border: 2px solid app-color('border');
                    color: app-color('dark-grey');
                    padding: 10px;
                    border-radius: 20px;
                    min-width: 80px;
                    text-align: center;
                    cursor: pointer;
                    &.active, &:hover {
                        background: app-color('border');
                        color: app-color('primary');
                    }
                }

            }
            &-quantity {
                .ant-input-number-group-addon {
                    padding: 0;
                    width: max-content;
                }
                .ant-input-number {
                    &:focus, &:focus-within {
                        border-color: app-color('border');
                        box-shadow: 0 0 0 2px rgba(app-color('border'), 0.1);
                    }
                }
                .ant-btn {
                    width: 40px;
                    height: 40px;
                    padding: 0;
                }
                .ant-input-number-input {
                    height: 45px;
                    width: 100px;
                    color: app-color('dark-grey');
                    @include app-typography('heading-S');
                }
                .ant-input-number-handler-wrap {
                    display: none;
                }
            }
        }
        &-action {
            margin: 10px 0 50px 0;
            display: flex;
            justify-content: flex-end;
            &-error {
                @include app-typography('body-XS');
                color: app-color('crimson');
                margin-bottom: 10px;
                text-align: center;
            }
            .buy-now-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                @include mq ($until: mobile) {
                    justify-content: center;
                }
                @include mq($from: mobile) {
                    max-width: 318px;
                }
            }
            .ch-btn {
                height: 60px;
            }
        }
    }
}