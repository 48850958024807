.ch-home {
    &-section {
        padding-bottom: 40px;
        padding-top: 20px;
        
        &-title {
            @include app-typography('heading-M');
            color: app-color('border');

            @include mq($until: tablet) {
                text-align: center;
            }
        }
        &:where(.separated) {
            border-bottom: 1px solid app-color('border');
            margin-bottom: 20px;
        }
    }
    &-shop-now {
        width: 100vw;
        margin-left: -50px;
        margin-right: -50px;
        margin-top: 40px;
        height: 100vh;
        position: relative;

        img {
            width: 100vw !important;
            height: 100vh !important;
            object-fit: cover;
        }

        &-overlay {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(app-color('secondary'), 75%);
            backdrop-filter: blur(4px);
            padding: 0 30px;

            &-container {
                display: flex;
                flex-direction: column;
                gap: 100px;
                align-items: center;
            }
            &-text {
                @include app-typography('heading-L');
                color: app-color('border');
                background: app-color('secondary');
                padding: 15px;
                border-radius: 5px;
                text-align: center;
            }
        }
    }
    &-goal {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0;

        p {
            @include app-typography('heading-S');
            line-height: 30px !important;
            color: app-color('border');
            max-width: 55%;
            text-align: center;
            @include mq($until: mobile) {
                max-width: 100%;
            }
        }
    }
}