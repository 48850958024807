.ch-footer {
    background: rgba(app-color('border'), 70%);
    padding: 40px;
    img {
        -webkit-filter: invert(1);
        filter: invert(1);
    }
    &-section {
        display: flex;
        gap: 100px;
        padding-bottom: 20px;
        border-bottom: 1.5px solid app-color('secondary');

        @include mq ($until: tablet) {
            flex-direction: column;
            gap: 50px;
        }
    }
    &-ownership {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    &-contact {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-title {
            @include app-typography('body-S-semi-bold');
            padding-bottom: 8px;
        }
        &-line {
            @include app-typography('body-XS')
        }
    }
    &-follow-us {
        &-title {
            @include app-typography('body-S-semi-bold');
            padding-bottom: 15px;
        }
        &-links {
            display: flex;
            gap: 20px;
            .anticon {
                color: app-color('primary');
                font-size: 30px;
                &:hover {
                    scale: 1.1;
                }
            }
        }
    }
}