.ch-category {
    &-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 50px;
        margin-top: 40px;
        @include mq($until: tablet) {
            flex-direction: column;
        }
    }
    &-card {
        position: relative;
        padding: 20px;
        border: 1px solid app-color('border');
        border-radius: 10px;
        height: 400px !important;
        width: max-content;
        max-width: 360px;
        cursor: pointer;
        box-shadow: 0 2.8px 2.2px rgba(app-color('border'), 0.034),
        0 6.7px 5.3px rgba(app-color('border'), 0.048),
        0 12.5px 10px rgba(app-color('border'), 0.06),
        0 22.3px 17.9px rgba(app-color('border'), 0.072),
        0 41.8px 33.4px rgba(app-color('border'), 0.086),
        0 100px 80px rgba(app-color('border'), 0.12);

        @include mq($until: tablet) {
            width: calc(100vw - 80px) !important;
        }

        &-img {
            position: relative !important;
            border-radius: 10px;
            width: 100% !important;
            height: 300px !important;
        }
        &-title {
            @include app-typography('heading-S');
            text-align: center;
            padding: 30px;
        }
    }
}