// Typography

@mixin app-typography($name: 'medium', $bold: false) {
    @if map-get($typography, $name) == null {
        @error '"#{$name}" is not an available type';
    }
    $display: map-get($typography, $name);

    @each $size, $settings in $display {
        @if $size == 'default' {
        @each $property, $value in $settings {
            @if $property == 'font-size' {
            font-size: rem($value)
            } @else {
            #{$property} :#{$value};
            }
        }
        } @else {
        @include mq($from: $size) {
            @each $property, $value in $settings {
            @if $property == 'font-size' {
                font-size: rem($value)
            } @else {
                #{$property} :#{$value};
            }
            }
        }
        }
    }

    @if $bold {
        font-weight: $font-weight-bold;
    }
}
  

