.ch-contact {
    &-page {
        display: flex;
        justify-content: center;
    }
    &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 800px;
    }
    &-title {
        @include app-typography('heading-M');
        color: app-color('border');
        text-align: center;
        margin-bottom: 40px;
    }
    &-desc {
        @include app-typography('heading-S');
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 30px !important;
        text-align: center;
    }
    &-contact {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        margin-bottom: 10px;
        margin-left: 90px;
        @include app-typography('body-S-semi-bold');
        @include mq($until: mobile) {
            margin-left: 0;
        }
        .anticon {
            font-size: 25px;
        }
        a {
            color: app-color('border');
            border-bottom: 1px solid app-color('border');
            padding-bottom: 3px;
            &:hover {
                border-bottom: none;
            }
        }
    }
}